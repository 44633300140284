.cookiespopup{
    .MuiDialog-container{
        .MuiDialog-paper {
            max-width: 939px !important;
            padding: 45px 44px 45px 40px !important;
            border-radius: 13px !important;
        }
    }
}
.lin15{
    line-height: 1.5!important;
}
.mb-37{
    margin-bottom: 37px!important;
}
.mb-33{
    margin-bottom: 33px!important;
}
.lh-16{
    line-height: 1.6!important;
}
.startpopup{
    .close{
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        color: white;
        background: #F89B53;
        z-index: 10000000;
        border-radius: 100%;
    }
    .MuiDialog-container{
        .MuiDialog-paper {
            max-width: 1131px !important;
            // border-radius: 13px !important;
            width: 90%;
            border-radius: 30px;
        }
    }
.content{
    width: 55%;
    // padding: 45px 44px 45px 40px !important;
    padding: 60px 26px 69px 51px !important;

}
button{
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 600;
    width: 198px;
    border-radius: 30px!important;
    height:50px;
}
.img{
    width: 45%;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.glider-img-box{
    position: relative;
    .main-img{
        height: 290px !important;
        width: 100% !important;
    }
    .logo{
        width: auto;
        height: 52px;
        position: absolute;
        left: 30px;
        top: 10px;
    }
    .layer{
        position: absolute;
        height: 100%;
        width: 48%;
        right: 0px;
        top: 0px;        
    }
}
.glider-btn-box{
    display: flex;
    position: absolute;
    left: -44px;
    bottom: 100px;
    gap: 10px;
    hr{
        min-height: 100px;
        height: 100%;
        border: 1.5px solid #F89B53 !important;
        opacity: 1 !important;
        margin: 0px !important;
    }
    &.number{
        bottom: 50px !important;
        hr{
            min-height: 50px;
            height: 100%;
            border: 1.5px solid #ffffff !important;
            opacity: 1 !important;
            margin: 0px !important;
        }
        button{
            font-size: 18px !important;
        }
    }
}
}



.popupvisibility{
    .close{
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
       
    }
    .MuiDialog-container{
        .MuiDialog-paper {
            max-width: 1000px !important;
            // border-radius: 13px !important;
            width: 90%;
        }
    }
.content{
    width: 55%;
    padding: 45px 44px 45px 40px !important;

}
.img{
    width: 40%;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.glider-img-box{
    position: relative;
    .main-img{
        height: 290px !important;
        width: 100% !important;
    }
    .logo{
        width: auto;
        height: 52px;
        position: absolute;
        left: 30px;
        top: 10px;
    }
    .layer{
        position: absolute;
        height: 100%;
        width: 48%;
        right: 0px;
        top: 0px;        
    }
}
.glider-btn-box{
    display: none;
    position: absolute;
    left: -44px;
    bottom: 100px;
    gap: 10px;
    hr{
        min-height: 100px;
        height: 100%;
        border: 1.5px solid #F89B53 !important;
        opacity: 1 !important;
        margin: 0px !important;
    }
    &.number{
        bottom: 50px !important;
        hr{
            min-height: 50px;
            height: 100%;
            border: 1.5px solid #ffffff !important;
            opacity: 1 !important;
            margin: 0px !important;
        }
        button{
            font-size: 18px !important;
        }
    }
}
}
.cookiepopup{
    button{
        text-transform: capitalize;
        font-size: 14px;
    }
}
.startpopup img.close {
    width: 35px;
    height: 35px;
}