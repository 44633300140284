.emoji-card{

    background: none !important;
    box-shadow: none !important;
    Button{
        background: white;
        width: 123px;
        height: 90px;
        border-radius: 21.76px;
        box-shadow: -0.09px 3.63px 35.53px -17.81px #757576;

    }
    h4{
        margin-top: 15px;
    }
}