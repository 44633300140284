.App{
    min-height: 100vh !important;
    // display: flex;
    // background:url(../assets/img/svg/spirals-top.svg) top left no-repeat,
    // url(../assets/img/svg/spirals-right.svg) bottom right no-repeat;
}

.loader{
    height: 100%;
    width: 100%;
    min-height: 100vh;

    background:url(../assets/img/svg/spirals-top.svg) top left no-repeat,
    url(../assets/img/svg/spirals-right.svg) bottom right no-repeat;
    .content{
        padding-top: 5%;
        h4{
            text-align: center;
            background: linear-gradient(97deg, #B47E54 -25.06%, rgba(168, 22, 191, 0.75) 26.8%, rgba(12, 0, 129, 0.78) 66.91%) !important;
            -webkit-text-fill-color: transparent !important;
            -webkit-background-clip: text !important;
        }
    }
}


.MuiLinearProgress-root{
    height: 24px !important;
    border-radius: 26px !important;
    background-color: transparent !important;
}
.MuiLinearProgress-bar{
    background:  linear-gradient(270.03deg, #F89B53 0.03%, #FFFFFF 102.98%) !important;
    height: 24px !important;
    border-radius: 26px !important;
}