
@media only screen and (min-width: 1200px){
    .Generate-Vacancy-btn button.primary-btn{
        height: 50px !important;
    }
    .paymentpage .contactusimg{
        display: block!important;
    }
}

@media only screen and (max-width: 1200px){
    body .article{
        flex-direction: column;        
        padding: 12px;
    }
    .readmoreimg{
        width: 100% !important;
    }
    .readmoreimg img {
        border-radius: 8px;
        width: 100% !important;
    }
}
@media only screen and (max-width: 930px){
 
   
    a.logomobil img {
        width: 100px;
    }
    .rightmenu{
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
        padding-left: 30px;

    }
    .mobilemenu button svg {
        /* width: 39px; */
        fill: #ff8e37;
    }
    body header{
        padding: 5px 0px;
    }
    .mobilemenu button {
        width: 33px;
        min-width: fit-content;
        border: 1px solid #f89b53 !important;
        padding: 0;
        height: 33px;
        margin-left: 12px;
    }
    .notification .secondary-btn ,  body .signUp-btn{
        height: 26px;
        padding: 3px 12px !important;
        font-size: 12px !important;
    }
    .menubar_smallscreen .MuiListItemText-root span.MuiTypography-root {
        font-size: 18px;
        color: #1E293B;
        font-weight: 500;
        font-family: 'Montserrat'!important;
    }
    .menubar_smallscreen .MuiListItemButton-root{
        margin-bottom: 0px;
    }
    .menubar_smallscreen .MuiListItemButton-root:hover{
        background-color: #e6e6e8;
    }
    .menubar_smallscreen {
        padding-top: 38px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    .bottom_menu button {
        align-items: center;
        text-align: left;
        width: 100%;
        font-size: 14px;
        color: #d05b02;
        font-weight: 500;
        margin-bottom: 0px;
        justify-content: flex-start;
        gap: 8px;
        padding: 0px;
        font-family: "Montserrat" !important;
    }
    .bottom_menu button svg {
        width: 22px;
        /* height: 28px; */
    }
    .bottom_menu{
        padding-bottom: 50px;
    }
    .startpopup .MuiDialog-container .MuiDialog-paper{
        border-radius: 12px;
    }
    body .service-card{
        margin-bottom: 0px;
        margin-top: 0px;
    }
    .postjob-card img{
        object-fit: cover;
        height: auto!important;
        width: 441px;
        width: 100%!important;
        margin-top: 20px;
    }
    
    .startpopup .content{
        width: 100%;
    }
    body .article{
        max-width: 100%;
        
        padding: 15px;
    }
    .SubscriptionPlan .SubscriptionPlan-card{
        max-width: 100% !important;
        margin-bottom: 25px;
    }
    body .SubscriptionPlan .active{
        max-width: 90% !important;
        margin-bottom: 0px;
    }
    body .SubscriptionPlan .active{
        position: static;
    }
    .content-img{
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 50px;
        height: 50px;
    }
    .card .Content h4.fs-28 {
        font-size: 22px !important;
    }
    .tabhriztal{
        flex-flow: row wrap;
        padding: 15px !important;
    }
    .readmorepag .readmorebanner h1 {
        font-size: 35px !important;
        width:80%;
        margin: auto;
    }
    .readmorebanner {
        height: 224px!important;
        margin-bottom: 30px!important;
    }
    .leftText{
        margin-bottom: 5px!important;
        padding: 12px!important;
        font-size: 12px;
        text-align: ri!important;
        justify-content: flex-end;
        min-height: auto!important;
        border: 1px solid gainsboro;
        margin-right: 4px!important;
    }
    .pannel_content {
        padding-left: 16px !important;
        margin-top: 70px;
    }

    .pannel_content h2 {
        font-size: 20px!important;
    }

    .pannel_content p {
        font-size: 12px;
    }
    .notification img {
        margin-right: 5px;
        height: 20px;
        width: 20px;
    }
    .notification:after, .notification:before,.langaugelist::after{
        display: none;
    }
    .langaugelist {
        margin-right: 0px;
        padding-right: 0px;
        position: relative;
        min-width: auto;
        width: auto !important;
    }
    div#demo-simple-select {
        padding: 0px;
    }
    img.usericon {
        width: 25px!important;
        margin-right: 8px!important;
    }
    .langaugelist svg{
        right: -14px!important;
        top: 0px!important;
      
    }
    #menu- .MuiMenu-list li{
        padding-left: 10px !important;
    }
    body .partnership .mt-148 {
        margin-top: 30px !important;
    }

}
@media only screen and (max-width: 900px){
    .rightmenuright.beforesignin .userimg {
        display: none;
    }
    .notification button, .singupdiv  {
        display: none;
    }
    
    .mobilemenu{
        display: block;
    }
    .login-img-box{
        display: none;
    }
    .langaugelist {
        min-width: auto;
    }
    .paycontainer {
        padding: 45px 15px!important;
    }
    .paymentcard , .paymnetmode{
        padding: 30px 15px!important;
    }
    body  .paycontainer  .SubscriptionPlan .active{
        margin-bottom: 20px;
    }
    .SubscriptionPlandiv{
        padding: 0px!important;
    }
    .boderbtn{
        border-bottom: 1px solid grey;
    }
   
}


@media screen and (min-device-width: 701px) and (max-device-width: 1400px){
    .singupdiv {
        padding-left: 8px;
    }
    .notification:after, .notification:before{
        width:0px;
    }
    .notification {
        align-items: center;
    }
    .langaugelist svg{
        right: -14px!important;
        top: 0px!important;
        display: none;
    }
    .singupbtn img {
        display: none;
        width: 14px;
    }
    img.usericon{
        width: 33px;
    }
    .langaugelist::after{
        margin: 0 12px;
    }
    .notification:after, .notification:before{
        margin: 0 10px;
    }
    .langaugelist{
        min-width: 80px;
    }
    body .rightmenuleft button {
        white-space: nowrap;
        font-size: 15px !important;
    }
    body .rightmenuleft  {margin-left: 10px;
    }
    .rightmenu{
        flex-flow: row nowrap;
    }
    body .rightmenuleft{
        gap: 15px!important;
    }
    a.logomobil img {
        width: 120px;
    }
    body .partners img {
        height: 70px !important;
        width: 70px !important;
    }
    body .fs-33 {
        font-size: 30px !important;
    }
    .revolution  {
        padding-top: 55px;
    }
    .revolution h3 {
        padding-left: 25px;
    }
    .revolution img {
        border-radius: 8px;
        width: 88%;
        margin: auto;
    }
    body h4.sightitl {
        margin-top: 55px;
    }
    body .content-img {
        position: absolute;
        right: 31px;
        bottom: 12px;
        width: 50px;
        height: 50px;
    }
    body .fs-28 {
        font-size: 22px !important;
    }
    .card{
        margin-right: 0px;
    }
    .hireimg img {
        padding: 20px;
        margin-top: 20px;
    }
    body .postjob-card{
        text-align: center;
    }
    body .postjob-card img{
        max-width: 360px;
        margin-inline: auto;
    }
    body .GenerationJobVacancy{
        padding: 20px !important;
    }

    body .intersted  h4{
        font-size: 14px!important;
        font-weight: 500!important;
    }
    body .intersted .fs-55 {
        font-size: 24px !important;
    }
    body .fs-27 {
        font-size: 22px !important;
    }
    body .fs-25 {
        font-size: 22px !important;
    }
    .Generate-Vacancy-btn button.primary-btn{
        height: 50px !important;
    }
    body .fs-48 {
        font-size: 24px !important;
    }
   body .fs-43 {
        font-size: 24px !important;
    }
    body .fs-40 {
        font-size: 24px !important;
    }
    body .fs-16 {
        font-size: 14px !important;
    }
    body .startpopup button{
        font-size: 14px;
        width: 100px;
        height: auto;
    }
    .titlr {
        margin-bottom: 20px;
    }
    body .fs-18 {
        font-size: 14px !important;
    }
    body .fs-22 {
        font-size: 19px !important;
    }
    body .SubscriptionPlan .SubscriptionPlan-card .title{
        font-size: 12px!important;
    }
    body .SubscriptionPlan .SubscriptionPlan-card.active .title{
        font-size: 12px !important;
    }
    body .SubscriptionPlan .SubscriptionPlan-card ul li{
        font-size: 12px !important;
    }
    body .fs-36 {
        font-size: 30px !important;
    }
    body .SubscriptionPlan{
        padding: 10px 20px 0px 15px;
    }
    body .SubscriptionPlan .SubscriptionPlan-card .sub-title{
        font-size: 12px !important;
    }
    body .SubscriptionPlan .SubscriptionPlan-card.active .sub-title{
        font-size: 12px !important;
    }
    body .SubscriptionPlan .SubscriptionPlan-card.false {
        padding-top: 42px;
        padding-bottom: 10px;
    }
    body .SubscriptionPlan-card.false button{
        height:42px;
        font-size: 12px;
    }
    body .SubscriptionPlan-card button{
        height: 42px;
        font-size: 12px;
    }
    body .divider-text {
        margin-bottom: 8px;
    }
    body .banner-pricing h4.fs-20 {
        font-size: 14px !important;
    }
    body .banner-pricing h3 {
        font-size: 24px;
    }
    body .banner-pricing {
        padding: 50px 30px;
        margin-bottom: 190px;

    }
    .profiltab {
        margin-top: 40px;
    }
    body .profiltab button {
        border: 1px solid #e8e8e8 !important;
        font-size: 12px !important;
        border-radius: 15px 15px 0px 0px !important;
    }
    .tablescroll{
        overflow: scroll;
    }
    .addbtnn {
        width: 105px;
    }
    .paymentpage .contactusimg{
        display: none;
    }
    body .footer ul li.fs-16 {
        align-items: center !important;
    }
    .footer .MuiListItem-root.MuiListItem-gutters > div {
        padding: 8px 0px;
        margin: 0px;
    }
}
@media only screen and (max-width: 700px){
    .menubar_smallscreen .MuiTouchRipple-root:hover, .menubar_smallscreen .MuiTouchRipple-root:focus{
        display: none;
    }
    .intersted .GenerationJobVacancy a .readybtnciscl {
        width: 54px;
    }
    body .footer ul li.fs-16 {
        align-items: center !important;
    }
    .langaugelist svg{
        display: none;
    }
    a.logomobil img {
        width: 120px;
    }
    .langaugelist {
        min-width: 60px;
    }
    .contactusimg,.imgnone{
        display: none;
    }
    .paymentpage .contactus {
        padding: 45px 15px !important;
    }
    .paymentpage  .cstm-pr-2 {
        padding-right: 0rem !important;
    }
    .paymentpage .paddingup{
        padding: 15px !important;
    }
    .revolution img {
        border-radius: 8px;
        margin-bottom: 20px!important;
        max-width: 480px;
        margin: auto;
        margin-top: 30px!important;
    }
    .revolution >div {
        text-align: center;
        margin: auto !important;
    }
    .companyimg>div {
        padding: 22px 15px;
    }
    .companyimg>div label>span, .companyimg>div button {
        height: 32px;
        width: 100px;
        padding: 0px;
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 500;
    }
    .tablescroll{
        overflow: scroll;
    }
    .addbtnn {
        width: 105px;
    }
    body .profiltab button {
        border: 1px solid #e8e8e8 !important;
        font-size: 12px !important;
        border-radius: 15px 15px 0px 0px !important;
    }
    body .tabinfo {
        padding: 15px;
        margin: 15px;
        box-sizing: border-box;
        margin-bottom: 60px;
        margin-top: 0px;
    }
    .profiltab button.Mui-selected {
        background-color: #F89B53;
        color: white;
        border-color: #F89B53 !important;
    }
    body .profiltab {
        padding: 15px!important;
        padding-bottom: 0px !important;
    }
    body .profileinfo {
        padding: 0px!important;
    }
    body .banner-pricing h4.fs-20 {
        font-size: 14px !important;
    }
    body .banner-pricing h3 {
        font-size: 24px;
    }
    body .banner-pricing {
        padding: 50px 30px;
        margin-bottom: 50px;
    }
    body .postjob-card{
        text-align: center;
    }
    body .postjob-card img{
        max-width: 360px;
        margin-inline: auto;
    }
    .titlr {
        margin-bottom: 20px;
    }
    body .fs-40 {
        font-size: 22px !important;
    }
    body .startpopup .content {
        width: 100%;
        padding: 30px !important;
    }
    body .startpopup button {
        font-size: 14px;
        width: 120px;
        height: auto;
    }
    body .startpopup img.close {
        width: 25px;
        height: 25px;
    }
    body .fs-43 {
        font-size: 24px !important;
        line-height: 1.2;
    }
    .banner{
        padding: 40px 25px;
    }
    body .fs-18 {
        font-size: 14px !important;
        line-height: 1.435 !important;
    }
    body .fs-16 {
        font-size: 12px !important;
    }
    button{
        height: 40px;
    }
    .Generate-Vacancy-btn button.primary-btn{
        min-width: 40px !important;
    height: 40px !important;
    }
    .banner{
        padding: 30px 40px!important;
    }
    body .fs-27 {
        font-size: 22px !important;
    }
    body .px-5 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
   body .service-card{
        padding: 30px 10px;
        margin-bottom: 5px;
        margin-top: 5px;
        width: 80%;
    }
    .readmoresection .MuiPaper-root {
        flex-direction: column;
        padding: 15px 15px 30px 15px;
    }
    .readmoresection .MuiPaper-root img {
        width: 100%!important;
        border-radius: 8px;
    }
    body .SubscriptionPlan .SubscriptionPlan-card.active .title{
        font-size: 20px !important;
    }
    body .SubscriptionPlan .SubscriptionPlan-card.active .sub-title{
        font-size: 14px!important;
    }
    .readmoresection .gap-20 {
        gap: 10px !important;
    }
   body .readmoresection{
        padding-top: 0px;
        margin-top: 5px !important;
    }
    .SubscriptionPlan{
        padding: 10px;
    }
    .gap-30 {
        gap: 0px !important;
    }
    body .fs-25 {
        font-size: 20px !important;
    }
    body .fs-16 {
        font-size: 14px !important;
    }
    .gap-30.service-section {
        gap: 0px !important;
    }
   body .fs-48 {
        font-size: 22px !important;
    }
    .recuitmentsection .fs-22 {
        font-size: 14px !important;
    }
    body .recuitmentsection .fs-18{
        font-size: 12px !important;
    }
    .recuitmentsection .gap-30 {
        gap: 8px !important;
    }
    body .postjob-card img{
        width:100%;
        height:auto!important;
        margin-top: 40px;
        max-height: 500px;
    }
    body .divider-text {
        margin-bottom: 20px;
    }
    body .SubscriptionPlan{
        margin: 0px auto auto auto;
        padding: 20px 0px;
    }
    body .SubscriptionPlan .SubscriptionPlan-card.false {
        padding-top: 10px;        padding: 20px;max-width: 100%;
    }
    .servicecard-container-flex.gap-30{
        gap:0px!important;
    }
    .servicecard-container {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    body .servicecard-container .divider-text {
        margin-bottom: 22px !important;
    }
    body .SubscriptionPlan .SubscriptionPlan-card .title{
        font-size: 20px !important;
    }
    body .SubscriptionPlan .SubscriptionPlan-card .sub-title{
        font-size: 14px !important;
    }
    body .SubscriptionPlan .SubscriptionPlan-card ul li{
        padding: 0px 0px 10px 0px;    font-size: 14px !important;
    }
    body .SubscriptionPlan-card.false button{
        font-size: 14px;    font-weight: 500;
    }
    body .SubscriptionPlan .activ{
        width: 85%;
    }
    .readmoreimg {
        width: 100%!important;
        margin-bottom: 20px!important;
    }
    .readmoreimg img{
        width: 100%;
    }
    .SubscriptionPlan .active{
        width: 85%!important;
    }
    body .GenerationJobVacancy {
        padding: 60px 30px !important;
    }
    .GenerationJobVacancy h4 {
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 21px;
    }
    .GenerationJobVacancy button {
        font-size: 12px;
       
    }
    .GenerationJobVacancy a .readybtnciscl {
        width: 20px;
    }
    body .ourwrk .card {
        margin-bottom: 10px!important;
    }
    body .ourwrk  .fs-17 {
        font-size: 14px !important;
    }
    body .ourwrk  .themebtn button,  body .ourwrk .themebtn a {
        height: 40px;
        font-size: 14px !important;
        font-weight: 600 !important;
        margin-bottom: 20px;
    }
    body .ourwrk .fs-22.sightitl,body .ourwrk .fs-40.sightitl {
        font-size: 30px !important;
        display: inline-block;
        margin-right: 10px;

    }
    .revolution{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .hireimg {
        padding: 15px;
    }
    .footer li {
        margin-bottom: 13px;
        padding: 0;
        font-size: 12px !important;
        align-items: flex-start;
        }
        img.footerlogo {
            margin-bottom: 20px;
        }
    .footer .footerlogo{
        margin-bottom: 30px;        
        width: 100px;
    }
    .copyritrw {
        justify-content: center;
        padding-top: 40px!important;
        padding-bottom: 20px!important;
        border-top: 1px solid gainsboro;
        margin-bottom: 0px !important;
    }
    .footer .copyritrw li:first-child {
        justify-content: center;
        text-align: center;
        width: 100%;
    }
    .copyritrw li{
        align-items: flex-start;
        font-size: 12px !important;
        font-weight: 500 !important;
    }
    .footer button {
        padding: 0px;
        border-width: 0px !important;
        height: auto;
    }    
    .footer button svg {
        width: 20px;
    }
   .tabhriztal{
        flex-flow: row wrap;
        padding: 15px !important;
    }
    .readmorepag .readmorebanner h1 {
        font-size: 35px !important;
        width:80%;
        margin: auto;
    }
    .readmorebanner {
        height: 224px!important;
        margin-bottom: 30px!important;
    }
    .leftText{
        margin-bottom: 5px!important;
        padding: 12px!important;
        font-size: 12px;
        text-align: ri!important;
        justify-content: flex-end;
        min-height: auto!important;
        border: 1px solid gainsboro;
        margin-right: 4px!important;
    }
    .pannel_content {
        padding-left: 16px !important;
        margin-top: 70px;
    }

    .pannel_content h2 {
        font-size: 20px!important;
    }

    .pannel_content p {
        font-size: 12px;
    }
}