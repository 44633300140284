.react-tel-input {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 39px;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: calc(100% - 39px);
    outline: none;
    height: 50px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}



.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #cacaca;
    /* border-radius: 3px 0 0 10px; */
    border-right: none;
    border-radius: 25px 0px 0px 25px;
}

/* added on 20-01-2025 */
.react-tel-input .selected-flag:focus, .react-tel-input .selected-flag:hover {
    background-color: unset !important;
}
.react-tel-input .flag-dropdown.open, .react-tel-input .flag-dropdown.open .selected-flag{
    border-radius: 25px 0 0 25px;
}

@media (max-width:767px){

    .react-tel-input .form-control {
       
        width: calc(100% - 23px);
       
    }
}