.contactus{
    padding:33px 8px 68px 48px !important;
    box-shadow: -0.09px 3.63px 35.53px -17.81px #757576 !important;
    border-radius: 21.75px !important;

}
.contactusimg{
    position: absolute;
    width: 38%;
    right: 65px;
    bottom: 121px !important;
    /* opacity: 0.5; */
}
.paddingup{
    padding-top: 61px!important;
}
.paymentpage h4 {
    padding-bottom: 21px;
    color: #000000;
}
.contactlabel {
    margin-bottom: 10px!important;
    color: #000000!important;
}
.paymentpage input{
    color: #000000!important;
    font-size: 14px!important;
    font-weight: 400!important;
    padding: 16px!important;
}
.paymentpage input::placeholder{
    color: #6B6B6B!important;
    font-size: 14px!important;
    font-weight: 400!important;
}
.paymentpage .primary-btn.submitbttt {
    height: 50px !important;
    border-radius: 22px !important;
    margin-bottom: 36px!important;
}
@media(max-width: 600px){
    .contactusimg{
        display: none;
    }
}