.mt-130{
    margin-top: 130px;
}
.fs-14{
    font-size: 14px;
}

.lh-15{
    line-height: 1.5;
}
.mt-54{
    margin-top: 54px!important;
}
.wd-80 {
    width: 70%;
}
.spacing{
    width: 80%;
    margin-top: 30px!important;
    margin-bottom: 54px!important;
}
.fs-27{
    font-size: 27px;
}
.content_pag {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    padding-right: 16px;
    font-size: 15px !important;
    font-weight: 400 !important;
}
.content_pag p{
    font-size: 15px !important;
    font-weight: 400 !important;
}
.content_pag ul{
    list-style: none;
}
.mb-120{
    margin-bottom: 120px;
}
.readmorebanner{
    height: 444px;
}

@media only screen and (max-width:700px){

    body .fs-35{
        font-size: 26px !important;
    }
    body .fs-22 {
        font-size: 18px !important;
        
        font-weight: 600 !important;
    }
    body .fs-15 {
        font-size: 14px !important;
    }
    .content_pag p {
        font-size: 14px !important;
        font-weight: 400 !important;
    }
    .spacing {
        width: 100%;
        margin-top: 30px !important;
        margin-bottom: 40px !important;
    }
    .mb-120.content_pag{
        margin-bottom: 80px;
        padding-left: 10px;
        padding-right: 10px;
    }
    body .card{
        margin-right: 0px;
        padding: 30px 15px !important;
    }
}