#root{
  min-height: 100vh;
}

iframe.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none !important;
}

/* Google translate icon animation class */
.VIpgJd-ZVi9od-aZ2wEe{
  display:none !important;
}


/*Google Translate icon class */
.VIpgJd-ZVi9od-aZ2wEe-OiiCO-ti6hGc {
  display: none;
  
}

.goog-te-gadget-icon{
  display: none;
}

body {
  top: 0px !important;
}