/* Chatbot.css */

.chatbot {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 877px;
    margin: 0 auto;
    padding: 20px;
    background-color: #FDFCFC;
    border: 1px solid #FDFCFC;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    height: 75vh; 
  }
  
  .messages {
    flex: 1;
    overflow-y: auto;
  }
  
  .message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  
  .user-message {
    justify-content: flex-end;
  }
  
  .bot-message {
    justify-content: flex-start;
  }
  
  .message-content {
    max-width: 70%;
    background-color: #ffffff;
    // border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .message-header {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .message-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .message-username {
    font-weight: 500 !important;
    font-size: 14px !important;
  }
  
  .message-text {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
  }
  
  .user-message .message-content {
    background: none !important;
    // background-color: #e0f7fa;
    // border-color: #b2ebf2;
    .message-text{
      padding:20px 19px !important;
      color: #2C2C2D !important;
      border: 1px solid #ECECEC;
      border-radius: 11px 2px 11px 11px !important;
      background-color: #E4C1F6;
      border-color: #E4C1F6;
      margin-right: 50px;
    }
    .message-header{
      flex-direction: row-reverse !important;
    }
  }
  
  .bot-message .message-content {
    background: none !important;
    .message-text{
      padding:20px 19px !important;
      color: #2C2C2D !important;
      border: 1px solid #ECECEC;
      border-radius: 2px 11px 11px 11px !important;
      background-color: #ffffff;
      border-color: #ECECEC;
      margin-left: 50px;
    }
  }
  
  .input-container {
    display: flex;
    align-items: center;
    // margin-top: 10px;
    padding: 14px 20px;
    background-color: #F7F9FB;
    border-radius: 20px;
    gap: 10px;
  }
  
  .message-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 14px;
  }
  
  
  .send-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .send-button:hover {
    background-color: #0056b3;
  }

  
/* Chatbot.css */

@keyframes streamIn {
    from {
      opacity: 0;
      transform: translateX(-10px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .message-content {
    display: inline-block;
    overflow: hidden;
    // white-space: nowrap;
  }
  
  .stream-message {
    display: flex;
    flex-wrap: wrap;
  }
  
  .stream-char {
    display: inline-block;
    opacity: 0;
    animation: streamIn 0.05s forwards;
  }
  
  .stream-char:nth-child(odd) {
    animation-delay: calc(0.05s * var(--index));
  }
  
  .stream-char:nth-child(even) {
    animation-delay: calc(0.05s * var(--index));
  }
  

  
  .message-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 14px;
  }
  .file-input-container{
    position: relative;
    height: 28px;
    width: 28px;
    img{
      height: 28px;
      width: 28px;
    }
  }
  .file-input {
   color: rgba(0, 0, 0, 0) !important;
   padding: 0px !important;
   width: 28px;
   height: 28px;
    cursor: pointer !important;
    position: absolute;
    left: 0;
    top: 0px;
    opacity: 0 !important;
    z-index: 999;
  }
  
  .send-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .send-button:hover {
    background-color: #0056b3;
  }

  .selected {
    border: 1px solid #007bff;
  }
  .continue-button{
    cursor: pointer;
    padding: 10px 15px;
  }
  .select-option{
    cursor: pointer;
    border: 1px solid #F89B53 !important;
    border-radius: 30px !important;
    padding: 5px 10px;
    margin-left: 55px;
    margin-top: 10px;
    &:hover{
      background-color: #F89B53 !important;
      color: #ffffff;
    }
  }

  .selected {
    background-color: #F89B53 !important;
      color: #ffffff;
  }

  @media(max-width: 600px){
    .message-content{
      max-width:calc(100% - 40px )!important ;
    }
  }

  .chatbot-dropdown{
    fieldset{
      border: 1px solid #ECECEC !important;
      border-radius: 2px 11px 11px 11px !important;
    }
    ul{
      width: 100% !important;
    }
  }

  .MuiMenu-list{
    width: 100% !important;
  }