.MuiMenu-list{
    border-radius: 21px !important;
    padding: 19px 28px !important;
    width: 330px !important;
    flex-grow: 1 !important;
    li{
        text-align: start !important;
        padding-left: 0px !important;
        border-bottom: 0.5px solid #D2D2D2 !important;
    }
}

.active{
    color:#F89B53 !important;
}
.rightmenu{
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    flex-flow: row wrap;
    gap: 0px;
    align-items: center;
}
.mobilemenu{
    display: none;
}


.notification:after, .notification:before {
    content: "";
    height: 32px;
    width: 0.5px;
    background: #00000040;
    margin: 0 20px;
    transform: translateY(13px);
}

.langaugelist{
    margin-right: 0px;
    padding-right: 29px;
    position: relative;
    min-width: 144px;
}

.langaugelist::after{
    content: '';
    position: absolute;
    top:0;
    right: 0;
    height: 32px;
    width: 1px;
    background: #00000040;
    margin: 0 20px;
    transform: translateY(13px);
}
.singupbtn{
    gap:8px;
}
.profileaccord {
    flex-direction: column;
}
.profillist {
    display: block;
}
.MuiListItem-root.MuiListItem-gutters>div {
    padding: 8px 15px;
    margin: 0px;
}
.MuiListItemButton-root .profillist span{
    display: block;
    width: 100%;
    text-align: left;
    align-items: center;
    text-align: left;
    width: 100%;
    font-size: 14px;
    color: #d05b02;
    font-weight: 500;
    margin-bottom: 0px;
    justify-content: flex-start;
    gap: 8px;
    padding: 8px 0px!important;
    font-family: "Montserrat" !important;
}
.bottom_menu li {
    padding: 0px 15px;
}
.profillist span svg {
    margin-right: 8px;
}
.menubar_smallscreen .MuiListItem-root.MuiListItem-gutters > div {
    padding: 4px 15px;
    margin: 0px;
}